<template>
  <section class="welfare-goods">
    <ul class="w-goods-wrap">
      <li class="w-goods-item" v-for="(item, index) in goodsList" :key="index">
        <router-link :to="`/zh/goods/detail/${item.id}?sign=1`" class="m-new-goods" v-if="item.image">
          <img :src="item.goods_pic_url"   alt="goods">
          <p class="van-ellipsis">{{item.goods_info.name}}</p>
          <div class="tips">签到价</div>
          <div class="goods-price">
            <span>$</span>
            <span>{{item.signin_price}}</span>
            <span>${{item.min_price}}</span>
          </div>
          <div class="top-right">
            <span>签</span>
            <span>{{item.sign_in_day}}天</span>
          </div>
        </router-link>
      </li>
      <li class="w-goods-item"></li>
    </ul>
  </section>
</template>

<script>

export default {
  name:'IndexNew',
  props:['goodsList']
}
</script>

<style lang="less" scoped>
.welfare-goods {
  padding: 0 10px;
  transition: all 0.5s ease;
  background-color: #fff;
  .w-goods-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .w-goods-item{
      margin-bottom: 12px;
      display: block;
      width: 150px;
      box-shadow: 0px 10px 15px 0px rgba(217, 217, 217, 0.384);
    }
    .m-new-goods {
      display: inline-block;
      width: 150px;
      color: #333;
      position: relative;
      &>img {
        width: 150px;
        height: 150px;
        border-radius: 5px;
      }
      &>p {
        margin-top: 8px;
        font-size: 12px;
        padding-left: 4px;
        letter-spacing: 0.31px;
        line-height: 18px;
      }
      .tips {
        width:44px;
        height:16px;
        background:linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%);
        border-radius:2px;
        font-size:10px;
        font-weight:400;
        color:#fff;
        line-height:15px;
        text-align: center;
        margin: 5px 0;
      }
      .goods-price {
        font-size: 12px;
        padding-left: 4px;
        color: #ED2A24;
        &>span:nth-of-type(2){
            font-size: 18px;
        }
        &>span:nth-of-type(3){
          color: #999999;
          text-decoration:line-through ;
          margin-left: 4px;
        }
        
      }
      .top-right {
        background-image: url('./../../assets/public/bg@2x.png');
        background-size: 100% 100%;
        width: 32px;
        height: 42px;
        position: absolute;
        top: 0;
        right: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        >span:nth-of-type(1) {
          font-size: 8px;
          color: #fff;
        }
        >span:nth-of-type(2) {
          font-size: 10px;
          color: #fff;
        }
      }
    }
  }
  
}
</style>