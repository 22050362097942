<template>
  <section class="m-welfare m-scroll" ref="scroll"  @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header" :style="`background:rgba(255,255,255,${(this.offsetTop-100) / 100});`">
      <img @click="handleBack" src="@/assets/goods/icon_back_2@2x.png" alt="back">
       <!-- <van-search v-model="searckKey" placeholder="请输入关键词" autofocus clearable
      @search="onSearch" shape="round" background="transparent"></van-search> -->
      <span :style="`opacity:${(this.offsetTop -60)  / 60};`">天天福利</span>
      <router-link to="/zh/cart" >
        <img src="@/assets/goods/cart.png" alt="img">
        <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
      </router-link>
    </div>
    <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};background-image: url(${bannerImg})`"></div>

    <!-- 签到区 商品区 -->
    <div class="sign-in">
      <div class="sign-title">
        <img src="@/assets/walfare/biaoti1@2x.png" alt="img">
        <span>连签享低价</span>
        <img src="@/assets/walfare/biaoti2@2x.png" alt="img">
      </div>
      <div class="sign-btn">
        <van-button :color="isSign ? 'linear-gradient(58deg, #e1e1e1, #b7b7b7)' : 'linear-gradient(58deg, #F2A291, #E65250)'"
        round block @click="signInHandle">{{isSign ? '已签到' : '签到'}}</van-button>
      </div>
      <div class="sign-tips">
        <span>
          本月签到 <strong>{{this_month}}</strong>  天
        </span>
        <!-- <span>分享补签</span> -->
      </div>

      <!-- 签到特价区 -->
      <welfare-goods :goodsList="signGoods"></welfare-goods>
    </div>

    <!-- 热销单品 -->
    <index-cell title="热销单品" value="" class="hot-sale" v-if="noMoreWelfare"></index-cell>
    <goods-columns @cartClick="handleCart" :data="hotGoods" :columns="2" v-if="noMoreWelfare"></goods-columns>
    <p class="search-no" v-if='noMore && noMoreWelfare'>暂无更多商品</p>  

    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MDot         from '@/components/zh/m-dot.vue'
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import WelfareGoods from '@/components/zh/welfare-goods.vue'
import IndexCell    from '@/components/zh/index-cell.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { getSignIn, signIn, getSignInColor, getSignInBanner, getSignInGoods, getSignInHotGoods } from '@/api/zh/welfare.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{  WelfareGoods,IndexCell, MDot, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop : 0,
      tab       : 0,          // tabs选中的面板
      recomnd   : 4,
      loading   : false,
      skuModal  : false,
      start     : false,
      start_dom : null,
      tabTop    : 44,
      dotAnimate: false,
      cartNum   : 0,
      scrollTop : false,
      less_time : 0,
      sign_in   : 0,
      this_month: 0,
      isSign    : false,
      bgColor  : '',
      bannerImg: '',
      signGoods: [],
      hotGoods: [],
      page: 1,
      islock: false,
      noMore: false,
      goodsId: '',
      noMoreWelfare: false,
      wpage: 1,
      searckKey: ''
    }
  },

  mounted(){
    this.getSignInHandle()
    this.getSignInColorHandle()
    this.getSignInBannerHandle()
    this.getSignInGoodsHandle()
    this.getSignInHotGoodsHandle()

    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth
    localStorage.setItem('_back', '/')
  },
  activated() {
    this.getSignInHandle()
  },
  methods:{
    onSearch() {
      // 前往搜索页面
      
    },
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          if(!this.noMoreWelfare) {
            this.wpage++
            this.getSignInGoodsHandle()
          } else {
            this.page++
            this.getSignInHotGoodsHandle()
          }
        }
      }
    },
    handleBack(){
      this.$router.go(-1)
    },

    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)

      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getSignInHandle() {
      // 获取签到数据
      getSignIn().then(res => {
        if(res) {
          this.this_month = res.month_signin
          this.isSign     = res.is_signin == 1 ? true : false
        } else {
          this.this_month = 0
          this.isSign     = false
        }
      })
    },
    signInHandle() {
      // 签到
      signIn().then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          this.getSignInHandle()
        }else{
          this.$notify({ type: 'warning', message: res.msg })
        }
      })
    },
    getSignInBannerHandle() {
      // 获取Banner
      getSignInBanner(this.$route.query.id).then(res => {
        if(res) {
          if(res.data[0]) {
            this.bannerImg = res.data[0].pic_url
          }
        }
      })
    },
    getSignInColorHandle() {
      // 获取背景颜色
      getSignInColor(this.$route.query.id).then(res => {
        if(res) {
          this.bgColor = res.data.color
        }
      })
    },
    getSignInGoodsHandle() {
      // 获取签到价商品
      this.islock = true
      getSignInGoods({page: this.wpage}).then(res => {
        if(res) {
          this.signGoods = this.signGoods.concat(res.data.data)
          if(res.data.data.length < 20) {
            this.noMoreWelfare = true
          }
        }
      }).finally(() => {
        this.islock = false
      })
    },
    getSignInHotGoodsHandle() {
      // 获取热销商品
      this.loading  = true
      this.islock   = true
      getSignInHotGoods({page:this.page}).then(res => {
        this.loading  = false
        this.islock   = false
        if(res) {
          this.hotGoods = this.hotGoods.concat(res.data.data)
        }else{
          this.noMore = true
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search-no {
  line-height:80px;
  text-align:center;
  color:#888;
  font-size:14px;
}
@import './welfare.less';
</style>