/** 
 *   天天福利
 */
import { get, post } from '@/untils/js/axios.js'

// 签到数据获取
export const getSignIn = params => get('/signin/info', params)

// 签到
export const signIn = params => post('/signin/handle', params)

// 签到页面banner
export const getSignInBanner = id => get(`/special/banner-list/${id}`)

// 签到页面颜色
export const getSignInColor = id => get(`/special/info/${id}`)

// 签到价商品
export const getSignInGoods = params => get('/signin/goods-list', params)

// 签到热销商品
export const getSignInHotGoods = params => get('/signin/hot-goods-list', params)


